import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import SEO from "@/components/SEO"
import { withLanguage } from "@/utils/i18n"
import { parseLinks } from "@/utils"
import Layout from "@/components/Layout"
import { graphql } from "gatsby"
import LabelText, { ShortLabelText } from "@/components/LabelText"
import Link, { PlainLink, ExternalLink } from "@/components/Link"
import { getDefendantName } from "@/utils/name"
import CentreContainer from "@/components/Container"
import { parseSentence } from "@/utils/defendant"
import { BasicInfo } from "@/components/Profile"
import styled from "styled-components"
import Stepper from "@/components/Stepper"
import Back from "@/components/Back"
import { computeProfile } from "@/utils/defendant"
import Loader from "@/components/Loader"

const RelatedLinks = styled.div`
  ${({ theme }) => `
    margin: 0 ${theme.spacing(1)}px;

    ul {
      padding-inline-start: 0;
    }
    li {
      list-style-type: none;
      margin-bottom: ${theme.spacing(1.5)}px;
    }
  `}
`

const CaseCard = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(3)}px;
    padding: ${theme.spacing(2)}px;
    background-color: ${theme.palette.background.paper};
    border: 1px ${theme.palette.primary.main} solid;
    box-shadow: ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0px 0px ${
    theme.palette.primary.main
  };

    .case__nums {
      margin-top: ${theme.spacing(1)}px;
    }

    .case__stepper {
      margin-bottom: ${theme.spacing(2)}px;
    }

    .case__incident {
      font-size: 1.25rem;
      margin-bottom: ${theme.spacing(2)}px;
    }

    .case__remark {
      margin-bottom: ${theme.spacing(2)}px;
    }
`}
`

const ChargeCard = styled.div`
  ${({ theme, statusColor, statusContrast }) => `
    margin-bottom: ${theme.spacing(4)}px;
    background-color: ${theme.palette.background.paper};

    .charge__name {
      display: inline-flex;
      padding: ${theme.spacing(0.5)}px ${theme.spacing(1)}px;
      font-family: "Noto Serif TC", serif;
      font-weight: 700;
      font-size: 1.25rem;
      background: black;
      border: 1px #555555 solid;
      box-shadow: ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0px 0px ${
    theme.palette.primary.main
  };
      margin-bottom: ${theme.spacing(2)}px;
      

      a {
        color: ${theme.palette.background.paper};
      }
    }

    .charge__name:hover {
      opacity: 50%;
      transition: 0.3s;
    }

    .charge__pleadGuilty {
      display: inline-block;
      margin: ${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(
    1
  )}px 0;
      border: 1px #570606 solid;
      color: #570606;
      font-family: "Noto Serif TC", serif;
      font-weight: 700;
      padding: ${theme.spacing(0.25)}px ${theme.spacing(1.5)}px;
    }

    .charge__status {
      display: inline-block;
      margin: ${theme.spacing(0.5)}px 0 ${theme.spacing(1)}px;
      border: 1px ${statusColor} solid;
      background-color: ${statusColor};
      color: ${statusContrast};
      font-family: "Noto Serif TC", serif;
      font-weight: 700;
      padding: ${theme.spacing(0.25)}px ${theme.spacing(1.5)}px;
    }

    .charge__remark {
      margin: ${theme.spacing(2)}px 0;
    }
`}
`

const DefendantTemplate = ({
  pageContext: { localePath },
  data: {
    cms: { people },
  },
}) => {
  const { i18n, t } = useTranslation()

  const [defendantCases, setDefendantCases] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const [thisPeople] = people
      const data = await fetch(
        `${window.location.origin}/data/defendants/${thisPeople.id}.json`
      ).then(r => r.json())

      setDefendantCases(data)
      setIsLoading(false)
    }

    fetchData()
  }, [])

  const [defendant] = people

  let references = parseLinks({
    rawLinks: withLanguage(i18n, defendant, "references"),
  })

  const { statusColor, statusContrast } = computeProfile({ defendant })

  return (
    <Layout localePath={localePath}>
      <SEO title={getDefendantName({ defendant, i18n })} />
      {isLoading ? (
        <Loader background="dark" minHeight={450} />
      ) : (
        <>
          <CentreContainer>
            <Back />
          </CentreContainer>
          <CentreContainer>
            <BasicInfo defendant={defendant} showAvatar={true} />
            {defendantCases.map((defendantSubCases, i) => {
              const progress = defendantSubCases.reduce((acc, dsc) => {
                return [
                  ...acc,
                  {
                    progress: dsc.progress,
                    type: dsc.type,
                    subtype: dsc.subtype,
                  },
                ]
              }, [])

              return (
                <CaseCard key={i}>
                  <div className="case__stepper">
                    <Stepper progress={progress} />
                  </div>
                  {defendantSubCases.map((c, j) => {
                    const {
                      thisDefendant,
                      incident,
                      next_hearing_date,
                      close_date,
                      judge,
                      judge_2,
                      judge_3,
                      ...caseDetails
                    } = c

                    const judges = [judge, judge_2, judge_3].filter(j => j)

                    references = [
                      ...references,
                      ...parseLinks({
                        rawLinks: withLanguage(i18n, c, "references"),
                      }),
                    ].filter(ref => ref && ref.text && ref.text.length)

                    const [{ charges, ...details }] = thisDefendant
                    const caseRemarks = withLanguage(
                      i18n,
                      caseDetails,
                      "remark"
                    )

                    const totalSentenceText = withLanguage(
                      i18n,
                      details,
                      "total_sentence"
                    )

                    const CASE_SUBTYPE_OPTIONS = {
                      conviction_appeal: {
                        // 定罪上訴
                        showCharge: true,
                      },
                      bail_appeal: {
                        // 保釋上訴
                        showCharge: false,
                      },
                      sentence_appeal: {
                        // 刑期上訴
                        showCharge: true,
                      },
                      sentence_review: {
                        // 覆核刑期
                        showCharge: true,
                      },
                      bail_apply: {
                        // 保釋申請
                        showCharge: false,
                      },
                      bail_apply_review: {
                        // 覆核保釋申請
                        showCharge: false,
                      },
                      clarify_legal_opinion: {
                        // 釐清法律觀點
                        showCharge: true,
                      },
                    }

                    return (
                      <>
                        <div className="case__nums">
                          <LabelText
                            label={
                              c.subtype
                                ? t(`case.subtype_${c.subtype}`)
                                : t("case.number")
                            }
                            text={c.case_nums.join(" | ")}
                          />
                        </div>
                        {incident && j === 0 && (
                          <div className="case__incident">
                            <Link
                              to={{
                                page: "template-incident",
                                lang: i18n.language,
                                obj: incident,
                              }}
                            >
                              {withLanguage(i18n, incident, "name")}
                            </Link>
                          </div>
                        )}
                        {caseRemarks && (
                          <div className="case__remark">{caseRemarks}</div>
                        )}
                        {totalSentenceText && (
                          <LabelText
                            label={t("case.total_sentence")}
                            text={totalSentenceText}
                          />
                        )}
                        {charges.map(charge => {
                          const penalties = parseSentence({ charge, t })
                          const chargeRemarks = withLanguage(
                            i18n,
                            charge,
                            "remark"
                          )
                          const chargeSentenceText = withLanguage(
                            i18n,
                            charge,
                            "sentence_sp"
                          )

                          return (
                            <ChargeCard
                              key={charge.id}
                              statusColor={statusColor}
                              statusContrast={statusContrast}
                            >
                              <div>
                                {(c.subtype && CASE_SUBTYPE_OPTIONS[c.subtype]
                                  ? CASE_SUBTYPE_OPTIONS[c.subtype].showCharge
                                  : true) && (
                                  <div className="charge__name">
                                    <PlainLink
                                      to={{
                                        page: "template-charge",
                                        lang: i18n.language,
                                        obj: charge.charge,
                                      }}
                                    >
                                      {withLanguage(
                                        i18n,
                                        charge.charge,
                                        "name"
                                      )}
                                    </PlainLink>
                                  </div>
                                )}
                              </div>
                              {charge.plead_guilty === "plead_guilty" && (
                                <div className="charge__pleadGuilty">
                                  {t("case.plead_guilty")}
                                </div>
                              )}
                              {charge.status && (
                                <div className="charge__status">
                                  {withLanguage(i18n, charge.status, "type")}
                                </div>
                              )}
                              <div>
                                {!!penalties.length && (
                                  <ShortLabelText
                                    label={t("case.penalty")}
                                    text={penalties.join(", ")}
                                  />
                                )}
                              </div>
                              {chargeSentenceText && (
                                <ShortLabelText
                                  label={t("case.sentence_sp")}
                                  text={chargeSentenceText}
                                />
                              )}
                              <div className="charge__desc">
                                {withLanguage(i18n, charge, "content")}
                              </div>
                              {chargeRemarks && (
                                <div className="charge__remark">
                                  {chargeRemarks}
                                </div>
                              )}
                            </ChargeCard>
                          )
                        })}
                        {close_date ? (
                          <ShortLabelText
                            label={t("case.close_date")}
                            text={close_date}
                          />
                        ) : (
                          next_hearing_date && (
                            <ShortLabelText
                              label={t("case.next_hearing_date")}
                              text={next_hearing_date}
                            />
                          )
                        )}
                        {!!judges.length &&
                          judges.map((j, i) => {
                            return (
                              <ShortLabelText
                                key={i}
                                label={withLanguage(i18n, j, "title")}
                                text={withLanguage(i18n, j, "name")}
                              />
                            )
                          })}
                      </>
                    )
                  })}
                </CaseCard>
              )
            })}
            {!!references.length && (
              <RelatedLinks>
                <LabelText
                  label={t("common.reference")}
                  text={
                    <ul>
                      {references.map(r => {
                        return (
                          <li key={r.url}>
                            <ExternalLink to={r.url}>{r.text}</ExternalLink>
                          </li>
                        )
                      })}
                    </ul>
                  }
                />
              </RelatedLinks>
            )}
          </CentreContainer>
        </>
      )}
    </Layout>
  )
}

export default DefendantTemplate

export const query = graphql`
  query DefendantQuery($defendantId: cms_uuid!) {
    cms {
      people(where: { id: { _eq: $defendantId } }) {
        ...DefendantBasicInfo
        references_zh
        references_en
        references_zh
        references_en
      }
    }
  }
`
