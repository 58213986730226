import React from "react"
import styled from "styled-components"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import { useTranslation } from "react-i18next"
import { navigate } from "gatsby"
const BackWrapper = styled.div`
  ${({ theme }) => `
    margin-left: -8px;
    display: inline-flex;
    align-item: center;
    flex-wrap: wrap;
    font-weight: 700;
    margin-top: ${theme.spacing(3)}px;
    cursor: pointer;
    color: ${theme.palette.secondary.main};

    ${theme.breakpoints.up("viewport7")} {
      margin-bottom: ${theme.spacing(3)}px;
    }

    .label {
        font-size: 0.9rem;
        line-height: 1.6rem !important;
    }
  `}
`

export default function Back() {
  const { t } = useTranslation()
  return (
    <BackWrapper onClick={() => navigate(-1)}>
      <div>
        <KeyboardArrowLeftIcon />
      </div>
      <span className="label">{t("common.back")}</span>
    </BackWrapper>
  )
}
