import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import Adjust from "@material-ui/icons/Adjust"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "transparent",
    width: "100%",
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  completed: {
    color: "white",
  },
  stepLabel: {
    fontWeight: 700,
    fontFamily: "Noto Serif TC, serif",
  },
}))

const useQontoStepIconStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.secondary.main,
    display: "flex",
    height: 24,
    alignItems: "center",
  },
  active: {
    fontSize: "2rem",
  },
  completed: {
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: theme.palette.secondary.main,
  },
}))

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles()
  const { completed } = props

  return (
    <div className={classes.root}>
      {completed ? (
        <Adjust className={classes.active} />
      ) : (
        <div className={classes.completed} />
      )}
    </div>
  )
}

function getSteps(progress) {
  let progressArray = ["case.in_progress", "case.closed"]

  if (progress.map(p => p.progress.id).includes(9)) {
    progressArray.push("case.appeal")
  }

  return progressArray
}

function getCurrentStep(progressArray) {
  const lastStep = progressArray[progressArray.length - 1]
  switch (lastStep.type) {
    case "original":
      switch (lastStep.progress.id) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
          return 0
        case 8:
        case 9:
          return 1
        default:
          return "Unknown stepIndex"
      }
    case "appeal":
    case "final_appeal":
      switch (lastStep.progress.id) {
        case 9:
          return 0
        case 8:
          return 1
        default:
          return 0
      }
  }
}

export default function HorizontalLabelPositionBelowStepper({ progress }) {
  if (!progress || !progress.length) {
    return
  }

  const tempProgress = progress.filter(
    p =>
      p.subtype !== "bail_appeal" &&
      p.subtype !== "bail_apply" &&
      p.subtype !== "bail_apply_review" &&
      p.subtype !== "clarify_legal_opinion"
  )

  const classes = useStyles()
  const steps = getSteps(tempProgress)
  const { t } = useTranslation()

  const currentStep = getCurrentStep(tempProgress)
  return (
    <Stepper className={classes.root} activeStep={currentStep} alternativeLabel>
      {steps.map((label, id) => {
        const isCompleted = id <= currentStep
        return (
          <Step
            key={label}
            completed={isCompleted}
            classes={isCompleted ? classes.completed : classes.notCompleted}
          >
            <StepLabel StepIconComponent={QontoStepIcon}>
              <div className={classes.stepLabel}>{t(label)}</div>
            </StepLabel>
          </Step>
        )
      })}
    </Stepper>
  )
}
